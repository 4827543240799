:root {
    --notion-font: ui-sans-serif,system-ui,apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,"Apple Color Emoji",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol";
    --fg-color: #37352f;
    --fg-color-0: rgba(55,53,47,.09);
    --fg-color-1: rgba(55,53,47,.16);
    --fg-color-2: rgba(55,53,47,.4);
    --fg-color-3: rgba(55,53,47,.6);
    --fg-color-4: #000;
    --fg-color-5: rgba(55,53,47,.024);
    --fg-color-6: rgba(55,53,47,.8);
    --fg-color-icon: var(--fg-color);
    --bg-color: #fff;
    --bg-color-0: hsla(44,6%,50%,.15);
    --bg-color-1: #f7f6f3;
    --bg-color-2: hsla(44,6%,50%,.15);
    --select-color-0: #2eaadc;
    --select-color-1: rgba(45,170,219,.3);
    --select-color-2: #d9eff8;
    --notion-red: #e03e3e;
    --notion-pink: #ad1a72;
    --notion-blue: #0b6e99;
    --notion-purple: #6940a5;
    --notion-teal: #4d6461;
    --notion-yellow: #dfab01;
    --notion-orange: #d9730d;
    --notion-brown: #64473a;
    --notion-gray: #9b9a97;
    --notion-red_background: #fbe4e4;
    --notion-pink_background: #f4dfeb;
    --notion-blue_background: #ddebf1;
    --notion-purple_background: #eae4f2;
    --notion-teal_background: #ddedea;
    --notion-yellow_background: #fbf3db;
    --notion-orange_background: #faebdd;
    --notion-brown_background: #e9e5e3;
    --notion-gray_background: #ebeced;
    --notion-red_background_co: hsla(0,74%,94%,.3);
    --notion-pink_background_co: rgba(244,223,235,.3);
    --notion-blue_background_co: rgba(221,235,241,.3);
    --notion-purple_background_co: rgba(234,228,242,.3);
    --notion-teal_background_co: rgba(221,237,234,.3);
    --notion-yellow_background_co: hsla(45,80%,92%,.3);
    --notion-orange_background_co: hsla(29,74%,92%,.3);
    --notion-brown_background_co: hsla(20,12%,90%,.3);
    --notion-gray_background_co: hsla(210,5%,93%,.3);
    --notion-item-blue: rgba(0,120,223,.2);
    --notion-item-orange: rgba(245,93,0,.2);
    --notion-item-green: rgba(0,135,107,.2);
    --notion-item-pink: rgba(221,0,129,.2);
    --notion-item-brown: rgba(140,46,0,.2);
    --notion-item-red: rgba(255,0,26,.2);
    --notion-item-yellow: rgba(233,168,0,.2);
    --notion-item-default: hsla(45,4%,80%,.5);
    --notion-item-purple: rgba(103,36,222,.2);
    --notion-item-gray: hsla(45,2%,60%,.4);
    --notion-max-width: 720px;
    --notion-header-height: 45px
}

.dark-mode {
    --fg-color: hsla(0,0%,100%,.9);
    --fg-color-0: var(--fg-color);
    --fg-color-1: var(--fg-color);
    --fg-color-2: var(--fg-color);
    --fg-color-3: var(--fg-color);
    --fg-color-4: var(--fg-color);
    --fg-color-5: hsla(0,0%,100%,.7);
    --fg-color-6: #fff;
    --fg-color-icon: #fff;
    --bg-color: #2f3437;
    --bg-color-0: #474c50;
    --bg-color-1: #3f4447;
    --bg-color-2: hsla(44,6%,50%,.15);
    --notion-red: #ff7369;
    --notion-pink: #e255a1;
    --notion-blue: #529cca;
    --notion-purple: #9a6dd7;
    --notion-teal: #4dab9a;
    --notion-yellow: #ffdc49;
    --notion-orange: #ffa344;
    --notion-brown: #937264;
    --notion-gray: hsla(195,2%,60%,.95);
    --notion-red_background: #594141;
    --notion-pink_background: #533b4c;
    --notion-blue_background: #364954;
    --notion-purple_background: #443f57;
    --notion-teal_background: #354c4b;
    --notion-yellow_background: #59563b;
    --notion-orange_background: #594a3a;
    --notion-brown_background: #434040;
    --notion-gray_background: #454b4e;
    --notion-red_background_co: rgba(89,65,65,.3);
    --notion-pink_background_co: rgba(83,59,76,.3);
    --notion-blue_background_co: rgba(120,162,187,.3);
    --notion-purple_background_co: rgba(68,63,87,.3);
    --notion-teal_background_co: rgba(53,76,75,.3);
    --notion-yellow_background_co: rgba(89,86,59,.3);
    --notion-orange_background_co: rgba(89,74,58,.3);
    --notion-brown_background_co: rgba(67,64,64,.3);
    --notion-gray_background_co: rgba(69,75,78,.3)
}
